import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import 'rxjs/add/operator/map';
// import { environment } from "../../environments/environment";
import { EnvService } from "./env.service";
import { AlertService } from "./alert.service";
import { SharedService } from "./shared.service";

@Injectable()
export class AuthenticationService {
  
  // private API_BASE = environment.apiBase;
  
  constructor(
    private env: EnvService,
    private http: HttpClient,
    private router: Router,
    private _SharedService: SharedService,
    private _AlertService: AlertService
  ) {}
  
  
  login(data): any {
    return this.http.post<Object>(
      `${this.env.apiBase}/user/login`,
      {
        phone_code: data.phone_code,
        phone_number: data.phone_number,
        password: data.password,
        last_question_id: data.last_question_id,
        last_answer_id: data.last_answer_id,
        attributes: data.attributes
      }
    ).subscribe(res => {
      if (res['user'] && res['user'].api_key) {
        localStorage.setItem('currentUser', JSON.stringify(res['user']));
        this._AlertService.success('Alert.Successful_login');
        this.router.navigate(['']);
        this._SharedService.emitConnection.emit();
      }
    }, err => {
      this._AlertService.error('Alert.Wrong_phone_or_password');
    });
  }
  
  /*login(data) {
    return this.http.post<Object>(
      `${this.env.apiBase}/user/login`,
      {
        phone_code: data.phone_code,
        phone_number: data.phone_number,
        password: data.password,
        last_question_id: data.last_question_id,
        last_answer_id: data.last_answer_id,
        attributes: data.attributes
      }
    ).map(user => {
      if (user.user && user.user.api_key) {
        localStorage.setItem('currentUser', JSON.stringify(user.user));
      }
      return user;
    });
  }*/
  
  logout(): void {
    // remove user from local storage to log user out
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      // let value = localStorage.getItem(key);
      if (key !== 'lang') {
        localStorage.removeItem(key);
      }
    }
    // localStorage.clear();
    this.router.navigate(['start']);
  }
  
}
