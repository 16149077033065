<div class="container">
  <div class="row">
    <div class="profile-page-tabs vertical_tabs_style col-3">
      <ul>
        <li>
          <a (click)="tab = 1"
             data-toggle="tab"
             href="#personal_info"
             target="_self"
             [ngClass]="{'active': tab === 1}">
            {{ 'User_profile.Personal_information_tab' | translate }}
          </a>
        </li>
        <li>
          <a (click)="tab = 2"
             data-toggle="tab"
             href="#personal_attributes"
             target="_self"
             [ngClass]="{'active': tab === 2}">
            {{ 'User_profile.Personal_attributes_tab' | translate }}
          </a>
        </li>
        <li>
          <a href="javascript:void(0)">
            {{ 'User_profile.Change_password_tab' | translate }}
          </a>
        </li>
        <li>
          <a href="javascript:void(0)">
            {{ 'User_profile.Documents_needed_tab' | translate }}
          </a>
        </li>
      </ul>
    </div>

    <div class="main-content col-9">
      <div class="tab-content">
        <div id="personal_info" class="tab-pane" [ngClass]="{'active': tab === 1}">
          <div class="user-data col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6" *ngIf="userData">
            <div class="user_image">
              <img src="{{'assets/images/user@3x.png'}}" alt="user image">
            </div>
            <div class="name">
              <p class="label-like">{{ 'User_profile.Name' | translate }}</p>
              <p>{{ userData.name }}</p>
            </div>
            <div class="second_name">
              <p class="label-like">{{ 'User_profile.Second_name' | translate }}</p>
              <p>{{ userData.second_name }}</p>
            </div>
            <div class="email">
              <p class="label-like">{{ 'User_profile.Email' | translate }}</p>
              <p>{{ userData.email }}</p>
            </div>
            <div class="phone_number">
              <p class="label-like">{{ 'User_profile.Phone_number' | translate }}</p>
              <p>{{ userData.phone }}</p>
            </div>
            <!--<div class="phone_number" *ngIf="userData.attributes.length > 0">
              <p><span *ngFor="let i of userData.attributes">[ {{ i.description }} ] </span></p>
              <p class="label-like">{{ 'User_profile.Attributes' | translate }}</p>
            </div>-->
          </div>
        </div>
        <div id="personal_attributes" class="tab-pane" [ngClass]="{'active': tab === 2}">
          <div class="attributes col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6" *ngIf="userData">
            <p *ngIf="userData.attributes.length <= 0">{{ 'User_profile.No_attributes_yet' | translate }}</p>
            <div class="attributes_list_wrapper" *ngIf="userData.attributes.length > 0">
              <ul>
                <li *ngFor="let i of userData.attributes">
                  {{i.description}}
                  <span *ngIf="i.value">( {{i.value}} )</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="change_password" class="tab-pane" [ngClass]="{'active': tab === 3}">content 3</div>
        <div id="documents_needed" class="tab-pane" [ngClass]="{'active': tab === 4}">content 4</div>
      </div>

      <!--<div class="user-data col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="userData">
        <div class="name">
          <p>{{ userData.name }}</p>
          <p>{{ 'User_profile.Name' | translate }}</p>
        </div>
        <div class="second_name">
          <p>{{ userData.second_name }}</p>
          <p>{{ 'User_profile.Second_name' | translate }}</p>
        </div>
        <div class="phone_number">
          <p>{{ userData.phone }}</p>
          <p>{{ 'User_profile.Phone_number' | translate }}</p>
        </div>
        <div class="phone_number" *ngIf="userData.attributes.length > 0">
          <p><span *ngFor="let i of userData.attributes">[ {{ i.description }} ] </span></p>
          <p>{{ 'User_profile.Attributes' | translate }}</p>
        </div>
      </div>-->
    </div>
  </div>
</div>

