import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from "@angular/router";
import { EnvServiceProvider } from './_services/env.service.provider';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalModule, BsDatepickerModule } from 'ngx-bootstrap';
import { InternationalPhoneModule } from 'ng4-intl-phone';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';

import { AlertService, AuthenticationService, SharedService, RegistrationService, UserProfileService, SurveyService } from './_services/index';
import { AlertComponent, PermissionDirective, ConfirmEqualValidatorDirective } from './_directives/index';
import { AuthGuard } from './_guards/index';
import { JwtInterceptor } from './_helpers/index';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { RegistrationComponent } from './registration/registration.component';
import { AcceptSmsComponent } from './registration/accept-sms/accept-sms.component';
import { SetupPwdComponent } from './registration/setup-pwd/setup-pwd.component';
import { SurveyComponent } from './survey/survey.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { Error404Component } from './error404/error404.component';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AlertComponent,
    PermissionDirective,
    FooterComponent,
    ConfirmEqualValidatorDirective,
    DashboardComponent,
    LoginComponent,
    FrontPageComponent,
    RegistrationComponent,
    AcceptSmsComponent,
    SetupPwdComponent,
    SurveyComponent,
    UserProfileComponent,
    Error404Component
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    InternationalPhoneModule,
    InternationalPhoneNumberModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    EnvServiceProvider,
    AlertService,
    AuthGuard,
    AuthenticationService,
    SharedService,
    RegistrationService,
    UserProfileService,
    SurveyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
