import { Component } from '@angular/core';
import { AlertService } from "./_services/index";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { EnvService } from "./_services/env.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  lang: string = localStorage.getItem('lang');
  
  constructor(
    private env: EnvService,
    private alertService: AlertService,
    private translate: TranslateService
  ) {
    this.lang ? translate.use(this.lang) : translate.use('ru');
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.translate.use(event.lang);
        localStorage.setItem('lang', event.lang);
      });
  }
  
  error(message: string) {
    this.alertService.error(message);
  }
  
  /* ROUTE ANIMATION (CONFLICTS WITH NORMAL PAGE LAYOUT (HEADER => CONTENT => FOOTER) BECAUSE OF POS. ABSOLUTE OF THE ROUTER-OUTLET SIBLINGS) */
  /*public getRouterOutletState(outlet): any {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }*/
  
}
