import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserProfileService } from "../_services/index";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.less']
})
export class UserProfileComponent implements OnInit {
  userData: any;
  public tab = 1;
  
  constructor(
    public _UserProfileService: UserProfileService
  ) {
    this.getMobileUsers();
  }

  ngOnInit() { }
  
  public getMobileUsers(): any {
    this._UserProfileService.getMobileUsers()
      .subscribe(res => {
        console.log(res);
        this.userData = res.users;
      }, err => {
        console.log(err);
      });
  }

}
