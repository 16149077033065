<div class="accept-sms-wrapper">

  <form name="acceptSmsForm" id="acceptSmsForm" class="acceptSmsForm" (ngSubmit)="acceptSmsForm.valid && submitSms()" [formGroup]="acceptSmsForm" #f="ngForm" novalidate>

    <div class="form-group">
      <div class="input-wrapper sms_code">
        <label for="sms_code">
          {{ 'Registration.Sms_code' | translate }}
          <span class="required_asterisk">*</span>
        </label>
        <input type="text" class="form-control" name="sms_code" id="sms_code" formControlName="sms_code"/>
      </div>
      <div class="form-control-feedback"
           *ngIf="acceptSmsForm.controls['sms_code'].invalid && acceptSmsForm.controls['sms_code'].touched">
        <p *ngIf="acceptSmsForm.controls['sms_code'].errors.required">{{'Shared.Field_is_required' | translate}}</p>
        <p *ngIf="acceptSmsForm.controls['sms_code'].errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
      </div>
    </div>

    <div class="controls-wrapper registration-forms">
      <button class="submit-btn" type="submit" [disabled]='!acceptSmsForm.valid'>{{ 'Shared.Continue_registration' | translate }}</button>
    </div>
  </form>
</div>
