<div class="login-wrapper">
  <form [formGroup]="loginForm" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>

    <div class="form-group">
      <label>{{ 'Header.Phone_number_label' | translate }}</label>
      <international-phone-number placeholder="" (change)="onchangeValidation()" [defaultCountry]="'de'" formControlName="myPhone" [required]="false"></international-phone-number>

      <div class="form-control-feedback"
           *ngIf="f.submitted && loginForm.controls['myPhone'].errors || loginForm.controls['myPhone'].errors && (loginForm.controls['myPhone'].dirty || loginForm.controls['myPhone'].touched)">
        <p *ngIf="f.submitted && loginForm.controls['myPhone'].errors.required || loginForm.controls['myPhone'].errors.required">{{'Shared.Field_is_required' | translate}}</p>
        <p *ngIf="loginForm.controls['myPhone'].errors.wrong_number">{{'Shared.Phone_number_is_not_valid' | translate}}</p>
      </div>
    </div>

    <div class="form-group">
      <div class="input-wrapper password">
        <label (click)="$event.preventDefault()" for="password">{{ 'Header.Password_label' | translate }}</label>
        <input type="password" class="form-control" placeholder="{{ 'Shared.Enter_your_password_please' | translate }}" name="password" id="password" formControlName="password"/>
      </div>
      <div class="form-control-feedback"
           *ngIf="f.submitted && loginForm.controls['password'].errors || loginForm.controls['password'].errors && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">
        <p *ngIf="f.submitted && loginForm.controls['password'].errors.required || loginForm.controls['password'].errors.required">{{'Shared.Field_is_required' | translate}}</p>
        <p *ngIf="f.submitted && loginForm.controls['password'].errors.minlength" translate [translateParams]="{ requiredLength: loginForm.controls['password'].errors.minlength.requiredLength }">
          Shared.Password_length_required
        </p>
      </div>
    </div>

    <button class="login-btn" type="submit">{{ 'Front_page.Log_in_btn' | translate }}</button>
    <!--<button class="register-btn" type="button" (click)="startRegistration()">{{ 'Front_page.Register' | translate }}</button>-->

  </form>
</div>
