import { Component, ElementRef, OnInit, Renderer2, TemplateRef } from '@angular/core';
import { AlertService } from "../_services/index";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { SurveyService } from "../_services/index";
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as moment_ from 'moment';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.less']
})
export class SurveyComponent implements OnInit {
  modalRef: BsModalRef;
  private USER;
  preSavedSurveyStatus: any;
  answerIsNotChosen = true;
  QAObject: {
    id: number;
    description: string;
    description2: string;
    tree_end?: number;
    registration?: number;
    answer_chosen?: boolean;
    multi_answer?: boolean;
    type?: number;
    answers: any[];
  };
  customAnswerValueForm: FormGroup;
  
  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _alertService: AlertService,
    private el: ElementRef,
    private renderer: Renderer2,
    private _SurveyService: SurveyService,
    private modalService: BsModalService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getQuestion();
  }
  
  public customAnswerValueFormInit(qa): void {
    this.customAnswerValueForm = this.fb.group({
      value: [ '', [Validators.required ]],
      date: [ moment_().format('DD-MM-YYYY'), [Validators.required ]]
    });
  }
  public customAnswerValue(): void {
    console.log(this.QAObject);
    if (this.QAObject['type']['type'] === 2) {
      this.QAObject.type['value'] = moment_(this.customAnswerValueForm.value.date, "DD-MM-YYYY").format("YYYY-MM-DD");
    } else {
      this.QAObject.type['value'] = this.customAnswerValueForm.value.value;
    }
    
    let qe = JSON.parse(localStorage.getItem('surveyData'));
    if (!qe) {
      localStorage.setItem('surveyData' , JSON.stringify({
        question_id: this.QAObject.id,
        answer_id: this.QAObject.answers[0].id,
        registration: this.QAObject.registration,
        attributes_id: [this.QAObject.type],
        previous_questions: [this.QAObject.id]
      }));
    } else {
      qe.attributes_id.push(this.QAObject.type);
      qe.previous_questions.push(this.QAObject.id);
      localStorage.setItem('surveyData' , JSON.stringify({
        question_id: this.QAObject.id,
        answer_id: this.QAObject.answers[0].id,
        registration: this.QAObject['registration'],
        attributes_id: qe.attributes_id,
        previous_questions: qe.previous_questions
      }));
    }
    
    this._SurveyService.submitAnswer({
      question_id: this.QAObject.id,
      answer_id: this.QAObject.answers[0].id,
      value: (this.USER) ? this.QAObject.type['value'] : ''
    }).subscribe(res => {
      if (this.USER) {
        this.USER['last_question_id'] = this.QAObject.id;
        this.USER['last_answer_id'] = this.QAObject.answers[0].id;
        localStorage.setItem('currentUser', JSON.stringify(this.USER));
      }
      this.QAObject = res.result;
      this.QAObject['registration'] = this.QAObject['registration'];
      for (let ii = 0; ii < this.QAObject.answers.length; ii++) {
        for (let z = 0; z < this.QAObject.answers[ii].attributes_id.length; z++) {
          if (this.QAObject.answers[ii].attributes_id[z].type > 0) {
            this.QAObject['type'] = this.QAObject.answers[ii].attributes_id[z];
          }
        }
      }
      this.answerIsNotChosen = true;
      this.mobileDeviceControl(this.QAObject);
    }, err => {
      console.log(err);
    });
    
  }
  
  openRegistrationWarningModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign( { 'class': 'registrationNeeded modal-dialog-centered' })
    );
  }
  
  public mobileDeviceControl(i): void {
    if (i['tree_end'] && this.route.snapshot.queryParams['device']) {
      location.replace('/404');
    }
  }
  
  public getQuestion(): void {
    this.getUserData();
    if (this.USER) {
      this.preSavedSurveyStatus = JSON.parse(localStorage.getItem('surveyData'));
      let q;
      let a;
      if (this.USER.last_question_id) {
        q = this.USER.last_question_id;
      } else if (this.USER.last_question_id === null && this.preSavedSurveyStatus) {
        q = this.preSavedSurveyStatus['question_id'];
      } else {
        q = '';
      }
      if (this.USER.last_answer_id) {
        a = this.USER.last_answer_id;
      } else if (this.USER.last_answer_id === null && this.preSavedSurveyStatus) {
        a = this.preSavedSurveyStatus['answer_id'];
      } else {
        a = '';
      }
      this._SurveyService.submitAnswer({
        question_id: q,
        answer_id: a
      }).subscribe(res => {
        this.QAObject = res.result;
        if (!this.QAObject['registration']) {
          this.QAObject['registration'] = 0;
        }
        if (this.QAObject['tree_end'] === -1) {
          this.QAObject['tree_end'] = 0;
        }
        for (let i = 0; i < this.QAObject.answers.length; i++) {
          for (let z = 0; z < this.QAObject.answers[i].attributes_id.length; z++) {
            if (this.QAObject.answers[i].attributes_id[z].type > 0) {
              this.QAObject['type'] = this.QAObject.answers[i].attributes_id[z];
            }
          }
        }
        this.customAnswerValueFormInit(this.QAObject);
        this.mobileDeviceControl(this.QAObject);
      }, err => {
        console.log(err);
      });
    } else {
      this._SurveyService.getQuestion()
        .subscribe(res => {
          this.QAObject = res.result;
          if (this.QAObject['tree_end'] === -1) {
            this.QAObject['tree_end'] = 0;
          }
          for (let i = 0; i < this.QAObject.answers.length; i++) {
            for (let z = 0; z < this.QAObject.answers[i].attributes_id.length; z++) {
              if (this.QAObject.answers[i].attributes_id[z].type > 0) {
                this.QAObject['type'] = this.QAObject.answers[i].attributes_id[z];
              }
            }
          }
          this.customAnswerValueFormInit(this.QAObject);
        }, err => {
          console.log(err);
        });
    }
  }
  public rerunSurvey(): void {
    localStorage.removeItem('surveyData');
    this.getUserData();
    this._SurveyService.getQuestion()
      .subscribe(res => {
        this.QAObject = res.result;
        this.QAObject['tree_end'] = 0;
        if (this.USER) {
          this.USER['last_question_id'] = '';
          this.USER['last_answer_id'] = '';
          localStorage.setItem('currentUser', JSON.stringify(this.USER));
        }
      }, err => {
        console.log(err);
      });
  }
  
  public answerQuestion(i, modal_template): void {
    this.getUserData();
    
    if (i && !this.QAObject.multi_answer) {
  
      // IF USER ANSWERED AND THiS IS A SINGLE-ANSWER QUESTION
      
      for (let q = 0; q < this.QAObject.answers.length; q++) {
        this.QAObject.answers[q].answer_chosen = false;
      }
      i['answer_chosen'] = true;
      this.answerIsNotChosen = false;
    } else if (i && this.QAObject.multi_answer) {
      
      // IF USER ANSWERED AND THiS IS A MULTI-ANSWER QUESTION
      
      if (i['answer_chosen']) {
        i['answer_chosen'] = false;
        this.answerIsNotChosen = true;
        for (let q = 0; q < this.QAObject.answers.length; q++) {
          if (this.QAObject.answers[q].answer_chosen) {
            this.answerIsNotChosen = false;
          }
        }
      } else {
        i['answer_chosen'] = true;
        this.answerIsNotChosen = false;
      }
    } else {
      
      // IF USER THAT ALREADY SELECTED ANSWER AND CLICKED 'NEXT QUESTION' BUTTON
      
      let objToSend = {};
      let arr = [];
      let answers_array = [];
      for (let q = 0; q < this.QAObject.answers.length; q++) {
        if (this.QAObject.answers[q].answer_chosen) {
          objToSend = {
            question_id: this.QAObject.id,
            registration: this.QAObject.answers[q].registration
          };
          answers_array.push(this.QAObject.answers[q].id);
          for (let f = 0; f < this.QAObject.answers[q].attributes_id.length; f++) {
            this.QAObject.answers[q].attributes_id[f]['value'] = '';
            arr.push(this.QAObject.answers[q].attributes_id[f]);
          }
        }
      }
      objToSend['answer_id'] = answers_array;
      objToSend['attributes_id'] = arr;
      
      let qe = JSON.parse(localStorage.getItem('surveyData'));
      if (!qe) {
        localStorage.setItem('surveyData' , JSON.stringify({
          question_id: objToSend['question_id'],
          answer_id: objToSend['answer_id'],
          registration: objToSend['registration'],
          attributes_id: objToSend['attributes_id'],
          previous_questions: [objToSend['question_id']]
        }));
      } else {
        let b = qe.attributes_id.concat(objToSend['attributes_id']);
        b = b.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.id === item.id
          ))
        );
        qe.previous_questions.push(objToSend['question_id']);
        localStorage.setItem('surveyData' , JSON.stringify({
          question_id: objToSend['question_id'],
          answer_id: objToSend['answer_id'],
          registration: objToSend['registration'],
          attributes_id: b,
          previous_questions: qe.previous_questions
        }));
      }
      
      if (objToSend['registration'] && !this.USER) {
        this.openRegistrationWarningModal(modal_template);
      } else {
        this._SurveyService.submitAnswer({
          question_id: objToSend['question_id'],
          answer_id: objToSend['answer_id']
        }).subscribe(res => {
          if (this.USER) {
            this.USER['last_question_id'] = objToSend['question_id'];
            this.USER['last_answer_id'] = objToSend['answer_id'];
            localStorage.setItem('currentUser', JSON.stringify(this.USER));
          }
          this.QAObject = res.result;
          this.QAObject['registration'] = objToSend['registration'];
          for (let ii = 0; ii < this.QAObject.answers.length; ii++) {
            for (let z = 0; z < this.QAObject.answers[ii].attributes_id.length; z++) {
              if (this.QAObject.answers[ii].attributes_id[z].type > 0) {
                this.QAObject['type'] = this.QAObject.answers[ii].attributes_id[z];
              }
            }
          }
          this.answerIsNotChosen = true;
          this.mobileDeviceControl(this.QAObject);
        }, err => {
          console.log(err);
        });
      }
    }
  }
  
  
  public previousQuestion(): void {
    let previousQuestion = JSON.parse(localStorage.getItem('surveyData'));
    this._SurveyService.getQuestion(previousQuestion)
      .subscribe(res => {
        this.answerIsNotChosen = true;
        this.QAObject = res.result;
        this.QAObject['tree_end'] = 0;
        for (let i = 0; i < this.QAObject.answers.length; i++) {
          for (let z = 0; z < this.QAObject.answers[i].attributes_id.length; z++) {
            if (this.QAObject.answers[i].attributes_id[z].type > 0) {
              this.QAObject['type'] = this.QAObject.answers[i].attributes_id[z];
            }
          }
        }
        previousQuestion.previous_questions.pop();
        localStorage.setItem('surveyData', JSON.stringify(previousQuestion));
      }, err => {
        console.log(err);
      });
  }
  
  public proceedToRegistration(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.router.navigate(['/registration']);
  }
  
}
