import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

import { AlertService, AuthenticationService, SharedService } from "../_services/index";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import 'rxjs/add/operator/do';

@Component({
  moduleId: module.id,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private elem: ElementRef,
    private _SharedService: SharedService
  ) { }

  ngOnInit() {
    // reset login status
    this.authenticationService.logout();
    this.loginForm = this.fb.group({
      myPhone: ['', [Validators.required]],
      password: ['', [Validators.minLength(6), Validators.required]]
    });
  }

  onchangeValidation(): void {

    if (this.elem.nativeElement.querySelector('.phone_number').getAttribute('dialCode') === 'false' && this.loginForm.controls['myPhone'].value.length) {
      this.loginForm.controls['myPhone'].setErrors({'wrong_number': true});
    }
  }

  public startRegistration(): void {
    this.router.navigate(['/registration']);
  }

  login() {
    let objToSent = {
      'phone_code': '+' + this.elem.nativeElement.querySelector('.phone_number').getAttribute('dialCode'),
      'phone_number': null,
      'password': this.loginForm.value.password
    };
    objToSent['phone_number'] = this.loginForm.value.myPhone.replace(objToSent.phone_code, "");
    console.log(objToSent);
    console.log(this.loginForm);
    this.authenticationService.login(objToSent);
  }

}
