<div class="container">
  <div class="row">
    <div class="main-content col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
      <div class="survey">

        <div class="qa-wrapper" *ngIf="QAObject">

          <div class="question" [ngClass]="{'qa-result': QAObject.tree_end}">
            {{QAObject.description}}
            <span *ngIf="!USER">{{QAObject.description2}}</span>
          </div>

          <div class="divider"></div>

          <div class="answers-wrapper">

            <div *ngIf="!QAObject.type">
              <div class="answer" [ngClass]="{ 'active': item.answer_chosen}" *ngFor="let item of QAObject.answers" (click)="answerQuestion(item, registrationModal)">
                <img [hidden]="item.answer_chosen" src="{{'assets/images/survayRadioUnchecked.png'}}" alt="answer_unchecked">
                <img [hidden]="!item.answer_chosen"  src="{{'assets/images/survayRadioChecked.png'}}" alt="answer_checked">
                <span>{{item.description}}</span>
              </div>
            </div>
            <div *ngIf="QAObject.type" class="answer custom_answer" [formGroup]="customAnswerValueForm">
              <input *ngIf="QAObject.type['type'] === 1" class="form-control" name="value" formControlName="value"/>

              <input *ngIf="QAObject.type['type'] === 2" type="text" name="date" id="date" class="form-control" [placeholder]="" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-green' }" formControlName="date" autocomplete="off" />

              <!--<input type="date" *ngIf="QAObject.type['type'] === 2" class="form-control" name="value" formControlName="value"/>-->
            </div>

          </div>

          <div class="controls-wrapper">

            <button *ngIf="QAObject.answers.length && QAObject.id != 1" class="controls previous-answer" (click)="previousQuestion()">
              <i class="fas fa-arrow-left"></i>
              {{'Survey.Prev_question' | translate}}
            </button>

            <button [disabled]="answerIsNotChosen" *ngIf="QAObject.answers.length && !QAObject.type" class="controls submit-answer" (click)="answerQuestion(false, registrationModal)">
              {{'Survey.Next_question' | translate}}
              <i class="fas fa-arrow-right"></i>
            </button>

            <button [disabled]="QAObject.type && ((QAObject.type.type === 1 && !customAnswerValueForm.value.value) || (QAObject.type.type === 2 && !customAnswerValueForm.value.date))" *ngIf="QAObject.type" class="controls submit-answer" (click)="customAnswerValue()">
              {{'Survey.Next_question' | translate}}
              <i class="fas fa-arrow-right"></i>
            </button>

          </div>

        </div>

        <div [hidden]="!QAObject" *ngIf="QAObject" [ngClass]="{'isHidden': USER}" class="tree-end-details-wrapper">
          <button class="register-btn" *ngIf="!QAObject || !QAObject.answers.length && !USER" (click)="proceedToRegistration()">
            {{ 'Shared.Register_btn' | translate }}
          </button>
        </div>

        <div *ngIf="!QAObject || !QAObject.answers.length" class="restart-process">
          <button (click)="rerunSurvey()">
            <i class="fas fa-undo-alt"></i>
            {{ 'Survey.Rerun_survey_btn' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #registrationModal>
  <div class="modal-body">
    <h1>{{ 'Survey.Need_to_register_title_text' | translate }}</h1>
    <p>{{ 'Survey.Need_to_register_details_text' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button class="modal-submit-btn no-icon-btn" (click)="proceedToRegistration()">
      <span>{{ 'Shared.Register_btn' | translate }}</span>
    </button>
    <button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
      <span>{{ 'Shared.Close_modal' | translate }}</span>
    </button>
  </div>
</ng-template>
