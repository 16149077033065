<div class="set-password-wrapper">
  <form name="setupPasswordForm" id="setupPasswordForm" class="setupPasswordForm" (ngSubmit)="setupPwdForm.valid && setupPassword()" [formGroup]="setupPwdForm" #f="ngForm" novalidate>

    <div class="form-group">
      <div class="input-wrapper password">
        <label for="password">
          {{ 'Registration.Password' | translate }}
          <span class="required_asterisk">*</span>
        </label>
        <input type="password" class="form-control" name="password" id="password" formControlName="password"/>
      </div>
      <div class="form-control-feedback"
           *ngIf="setupPwdForm.controls['password'].invalid && setupPwdForm.controls['password'].touched">
        <p *ngIf="setupPwdForm.controls['password'].errors.required">{{'Shared.Field_is_required' | translate}}</p>
        <p *ngIf="setupPwdForm.controls['password'].errors.minlength" translate [translateParams]="{ requiredLength: setupPwdForm.controls['password'].errors.minlength.requiredLength, charactersLeft: setupPwdForm.controls['password'].errors.minlength.requiredLength - setupPwdForm.controls['password'].errors.minlength.actualLength }">
          Shared.Password_length_required
        </p>
      </div>
    </div>

    <div class="form-group">
      <div class="input-wrapper password_confirmation">
        <label for="password_confirmation">
          {{ 'Registration.Password_confirmation' | translate }}
          <span class="required_asterisk">*</span>
        </label>
        <input type="password" appConfirmEqualValidator="password" class="form-control" name="password_confirmation" id="password_confirmation" formControlName="password_confirmation"/>
      </div>
      <div class="form-control-feedback"
           *ngIf="setupPwdForm.controls['password_confirmation'].invalid && setupPwdForm.controls['password_confirmation'].touched">
        <p *ngIf="f.submitted && setupPwdForm.controls['password_confirmation'].errors.required || setupPwdForm.controls['password_confirmation'].errors.required">{{'Shared.Field_is_required' | translate}}</p>
        <p *ngIf="setupPwdForm.controls['password_confirmation'].errors.notEqual">{{'Shared.Passwords_arent_equal' | translate}}</p>
      </div>
    </div>

    <div class="controls-wrapper registration-forms">
      <button class="submit-btn" type="submit" [disabled]='!setupPwdForm.valid'>{{ 'Shared.Continue_registration' | translate }}</button>
    </div>
  </form>
</div>
