import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard } from "./_guards/index";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { FrontPageComponent } from "./front-page/front-page.component";
import { RegistrationComponent } from "./registration/registration.component";
import { AcceptSmsComponent } from "./registration/accept-sms/accept-sms.component";
import { SetupPwdComponent } from "./registration/setup-pwd/setup-pwd.component";
import { SurveyComponent } from "./survey/survey.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { Error404Component } from "./error404/error404.component";

const routes: Routes = [
  { path: 'start', component: FrontPageComponent },
  { path: 'registration',
    component: RegistrationComponent,
    children: [
      { path: 'accept-sms', component: AcceptSmsComponent },
      { path: 'setup-password', component: SetupPwdComponent }
    ]
  },
  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'survey', component: SurveyComponent },
  { path: '**', component: Error404Component }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  declarations: []
})
export class AppRoutingModule {}
