<div class="container">
  <div class="row">
    <div class="main-content col-12">
      <h5 class="welcome-msg" translate [translateParams]="{ user_name: user.name }">
        Dashboard.Welcome_msg
      </h5>

      <div class="survey">
        <app-survey></app-survey>
      </div>
    </div>
  </div>
</div>
