<ngx-loading-bar></ngx-loading-bar>

<app-header></app-header>
<app-alert></app-alert>

<main>
  <router-outlet></router-outlet>
</main>

<app-footer></app-footer>
