<div class="container">
  <div class="row">
    <div class="front-page-main-content col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="wrapper">

        <div class="front-page-title">
          <h4>{{ 'Front_page.Title_for_the_survey' | translate }}</h4>
          <p>{{ 'Front_page.Subtitle_for_the_survey' | translate }}</p>
        </div>

        <div class="divider"></div>

        <button class="start-survey-btn" (click)="startSurvey()">
          {{ 'Front_page.Start_survey_btn' | translate }}
        </button>

        <div class="img-wrapper">
          <img src="{{'assets/images/illustration.png'}}" alt="fototax flow illustration">
        </div>
      </div>
    </div>
  </div>
</div>
