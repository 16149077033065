import {Component, ElementRef, OnInit, Renderer2, Input} from '@angular/core';
import { AlertService, RegistrationService, SharedService, AuthenticationService } from "../../_services/index";
import { ActivatedRoute, Router } from "@angular/router";

import { FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-setup-pwd',
  templateUrl: './setup-pwd.component.html',
  styleUrls: ['./setup-pwd.component.less']
})
export class SetupPwdComponent implements OnInit {
  @Input() phone_code: string;
  @Input() phone_number: string;
  setupPwdForm: FormGroup;

  constructor(
    private _RegistrationService: RegistrationService,
    private router: Router,
    private route: ActivatedRoute,
    private _alertService: AlertService,
    private fb: FormBuilder,
    private _SharedService: SharedService,
    private _AuthService: AuthenticationService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm(): void {
    this.setupPwdForm = this.fb.group({
      password: ['', [Validators.minLength(6), Validators.required]],
      password_confirmation: ['', [Validators.minLength(6), Validators.required]]
    });
  }

  public setupPassword(): void {
    let objToSent = JSON.parse(localStorage.getItem('tempUserData'));
    let surveyStatus = JSON.parse(localStorage.getItem('surveyData'));
    objToSent['password'] = this.setupPwdForm.value.password;

    console.log(objToSent);
    this._RegistrationService.setupPassword(objToSent)
      .subscribe(res => {
        let tempLoginData = {
          'phone_code': this.phone_code,
          'phone_number': this.phone_number,
          'password': this.setupPwdForm.value.password,
          'last_question_id': (surveyStatus) ? surveyStatus.question_id : null,
          'last_answer_id': (surveyStatus) ? surveyStatus.answer_id : null,
          'attributes': (surveyStatus) ? surveyStatus.attributes_id : null
        };
        console.log(tempLoginData);
        this._AuthService.login(tempLoginData)
          .subscribe(success => {
            console.log(success);
            this._alertService.success('Alert.Successful_login');
            this.router.navigate(['']);
            this._SharedService.emitConnection.emit();
          }, error => {
              console.log(error);
          });
      }, error => {
        console.log(error);
        this._alertService.error('Alert.Opps_something_went_wrong');
      });
  }

}
