import {Component, OnInit, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { AlertService, AuthenticationService, SharedService } from "../../_services/index";
import { TranslateService } from "@ngx-translate/core";
import {ActivatedRoute, Router, NavigationEnd } from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  @ViewChild('globalHeader') globalHeader: ElementRef;
  
  lang = localStorage.getItem('lang');
  user: any = JSON.parse(localStorage.getItem('currentUser'));
  private loginDropdownToggle = false;
  
  constructor(
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private _SharedService: SharedService,
    public router: Router,
    public route: ActivatedRoute,
    public el: ElementRef,
    public renderer: Renderer2
  ) {
    this.lang = this.lang === null ? 'ru' : this.lang;
    router.events.subscribe((val) => {
      if (this.loginDropdownToggle) {
        this.toggleDropdown();
      }
      if (val instanceof NavigationEnd ) {
        if (this.route.snapshot.queryParams['device']) {
          this.renderer.setStyle(this.globalHeader.nativeElement, 'display', 'none');
        }
      }
    });
  }
  
  public getUser(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
  }
  
  ngOnInit() {
    this._SharedService.emitConnection
      .subscribe((res) => {
        this.getUser();
      });
  }
  
  public toggleDropdown(e?): void {
    this.loginDropdownToggle = !this.loginDropdownToggle;
  }
  
  public logout(): void {
    this.authenticationService.logout();
    this.getUser();
  }
  
  public useLanguage(lang): void {
    this.lang = lang;
    this.translate.use(lang);
    this._SharedService.changeLanguageEvent.emit(lang);
  }
}
