<div class="registration-tpl container">
  <div class="row">
    <div class="main-content col-12">
      <div class="col-12 page_title" *ngIf="!stepsTrigger.first_step">
        <p>{{ 'Registration.First_step_info' | translate}}</p>
      </div>
      <div class="registration-first-step" *ngIf="!stepsTrigger.first_step">
        <form name="registerMobileUserForm" id="registerMobileUserForm" class="registerMobileUserForm" (ngSubmit)="registrationForm.valid && registerMobileUser()" [formGroup]="registrationForm" #f="ngForm" [ngClass]="{ 'ng-submitted-error': f.submitted && f.form.invalid }" novalidate>
          <div *ngIf="stepsTrigger.first_step" class="first-step-cover">
            <i class="far fa-check-circle"></i>
            <i class="far fa-edit edit-icon" (click)="restartRegistration()"></i>
          </div>
          <div class="form-group">
            <div class="input-wrapper name">
              <label for="name">
                {{ 'Registration.Name' | translate }}
                <span class="required_asterisk">*</span>
              </label>
              <input class="form-control" name="name" id="name" formControlName="name"/>
            </div>
            <div class="form-control-feedback"
                 *ngIf="f.submitted && registrationForm.controls['name'].errors || registrationForm.controls['name'].errors && (registrationForm.controls['name'].dirty || registrationForm.controls['name'].touched)">
              <p *ngIf="f.submitted && registrationForm.controls['name'].errors.required || registrationForm.controls['name'].errors.required">{{'Shared.Field_is_required' | translate}}</p>
              <p *ngIf="registrationForm.controls['name'].errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
            </div>
          </div>

          <div class="form-group">
            <div class="input-wrapper second_name">
              <label for="second_name">
                {{ 'Registration.Second_name' | translate }}
                <span class="required_asterisk">*</span>
              </label>
              <input class="form-control" name="second_name" id="second_name" formControlName="second_name"/>
            </div>
            <div class="form-control-feedback"
                 *ngIf="f.submitted && registrationForm.controls['second_name'].errors || registrationForm.controls['second_name'].errors && (registrationForm.controls['second_name'].dirty || registrationForm.controls['second_name'].touched)">
              <p *ngIf="f.submitted && registrationForm.controls['second_name'].errors.required || registrationForm.controls['second_name'].errors.required">{{'Shared.Field_is_required' | translate}}</p>
              <p *ngIf="registrationForm.controls['second_name'].errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
            </div>
          </div>

          <div class="form-group">
            <div class="input-wrapper email">
              <label for="email">
                {{ 'Registration.User_email' | translate }}
                <span class="required_asterisk">*</span>
              </label>
              <input type="email" placeholder="name@email.com" class="form-control" name="email" id="email" formControlName="email"/>
            </div>
            <div class="form-control-feedback"
                 *ngIf="f.submitted && !!registrationForm.controls.email.errors || !!registrationForm.controls.email.errors && (!!registrationForm.controls.email.dirty || !!registrationForm.controls.email.touched)">
              <p *ngIf="f.submitted && !!registrationForm.controls.email.errors.required || !!registrationForm.controls.email.errors.required">
                {{'Shared.Field_is_required' | translate}}
              </p>
              <p *ngIf="f.submitted && !!registrationForm.controls.email.errors.pattern">
                {{'Shared.Email_isnt_valid' | translate}}
              </p>
              <p *ngIf="!!registrationForm.controls.email.errors.maxlength">
                {{'Shared.Field_is_too_long' | translate}}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="input-wrapper">
              <label>
                {{ 'Registration.Phone_number' | translate }}
                <span class="required_asterisk">*</span>
              </label>
              <international-phone-number placeholder="" (change)="onchangeValidation()" [defaultCountry]="'de'" formControlName="phone_number" [required]="false"></international-phone-number>
            </div>
            <div class="form-control-feedback"
                 *ngIf="f.submitted && registrationForm.controls['phone_number'].errors || registrationForm.controls['phone_number'].errors && (registrationForm.controls['phone_number'].dirty || registrationForm.controls['phone_number'].touched)">
              <p *ngIf="f.submitted && registrationForm.controls['phone_number'].errors.required || registrationForm.controls['phone_number'].errors.required">{{'Shared.Field_is_required' | translate}}</p>
              <p *ngIf="registrationForm.controls['phone_number'].errors.wrong_number">{{'Shared.Phone_number_is_not_valid' | translate}}</p>
            </div>
          </div>

          <div class="controls-wrapper registration-forms">
            <button class="submit-btn" type="submit" [disabled]='!registrationForm.valid'>{{ 'Shared.Continue_registration' | translate }}</button>
          </div>
        </form>
      </div>
      <div class="continuing-registration-steps-wrapper">
        <div class="registration-second-step-wrapper" *ngIf="stepsTrigger.first_step && !stepsTrigger.accept_sms.success">
          <div class="col-12 page_title">
            <p>{{ 'Registration.SMS_step_info' | translate}}</p>
          </div>
          <div class="registration-second-step">
            <app-accept-sms [stepsTrigger]="stepsTrigger" (messageEvent)="receiveMessage($event)"></app-accept-sms>
          </div>
        </div>

        <div class="registration-third-step-wrapper" *ngIf="stepsTrigger.accept_sms.success">
          <div class="col-12 page_title">
            <p>{{ 'Registration.PWD_step_info' | translate}}</p>
          </div>
          <div class="registration-third-step">
            <app-setup-pwd [phone_code]="userTempData.phone_code" [phone_number]="userTempData.phone_number"></app-setup-pwd>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
