import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
// import { environment } from "../../environments/environment";
import { EnvService } from "./env.service";

@Injectable()
export class UserProfileService {
  
  // private API_BASE = environment.apiBase;
  private USER = JSON.parse(localStorage.getItem('currentUser'));
  
  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }
  
  constructor(
    private env: EnvService,
    private http: HttpClient
  ) { }
  
  public getMobileUsers(): Observable<any> {
    const url = `${this.env.apiBase}/user`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.get<Object>(url, {params});
  }

}
