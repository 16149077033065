<div class="container header" id="header" #globalHeader>
  <div class="row">
    <div class="col col-12">
      <h4 class="header-logo">
        <a [routerLink]="['']">
          <img src="{{'assets/images/logotax.png'}}" alt="logo">
        </a>
      </h4>
      <ul class="nav-menu">

        <li *ngIf="!user && router.url !== '/registration'" class="nav-item sign_up">
          <a *ngIf="!user" class="sign_up_btn" [routerLink]="['registration']">
            <i class="fas fa-user-plus"></i>
            {{ 'Header.Sign_up_btn' | translate }}
          </a>
        </li>

        <li *ngIf="!user" class="nav-item log_in_dropdown">
          <span class="dropdown log_in_dropdown_menu_btn" id="log_in_dropdown_menu" (click)="toggleDropdown($event)" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-sign-in-alt"></i>
              {{ 'Header.Log_in_dropdown_btn' | translate }}
          </span>
          <div [ngClass]="{'show': loginDropdownToggle}" class="login-dropdown-menu dropdown-menu" aria-labelledby="log_in_dropdown_menu">
            <app-login></app-login>
          </div>
        </li>

        <li class="nav-item language" [class.front-page]="!user">
          <div class="dropdown lang_dropdown_menu_wrapper">
            <button class="lang_dropdown_menu_btn" type="button" id="lang_dropdown_menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span *ngIf="lang">{{lang}}</span>
              <i class="fas fa-chevron-down"></i>
            </button>
            <div class="lang-dropdown-menu dropdown-menu" aria-labelledby="lang_dropdown_menu">
              <a *ngIf="lang !== 'en'" (click)="useLanguage('en')">EN</a>
              <a *ngIf="lang !== 'ru'" (click)="useLanguage('ru')">RU</a>
            </div>
          </div>
        </li>

        <li *ngIf="user && router.url !== '/profile'" class="nav-item user-profile">
          <a *ngIf="user" class="user_profile_btn" [routerLink]="['profile']">
            <img src="{{'assets/images/user.png'}}" alt="user photo">
            {{user.name}}
          </a>
        </li>
        <li *ngIf="user" class="nav-item logout">
          <a class="logout_btn" (click)="logout()">{{ 'Header.Logout' | translate }}</a>
        </li>

      </ul>
    </div>
  </div>
</div>
