import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
// import { environment } from "../../environments/environment";
import { EnvService } from "./env.service";

@Injectable()
export class SurveyService {
  // private env = environment.apiBase;
  private USER = JSON.parse(localStorage.getItem('currentUser'));
  
  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }
  
  constructor(
    private env: EnvService,
    private http: HttpClient
  ) { }
  
  /* TWO METHODS BELOW ARE FOR USERS THAT AREN'T REGISTERED */
  public getQuestion(previousQuestion?, value?): Observable<any> {
    this.getUserData();
    let previous_question;
    if (previousQuestion) {
      previous_question = previousQuestion.previous_questions[previousQuestion.previous_questions.length - 1];
    } else {
      previous_question = '';
    }
    
    if (this.USER) {
      const url = `${this.env.apiBase}/auth/user/question`;
      let params = new HttpParams()
        .append('userId', this.USER.user_id)
        .append('question_id', previous_question)
        .append('value', (value) ? value : '');
      return this.http.get<Object>(url, {params});
    } else {
      const url = `${this.env.apiBase}/user/question`;
      let params = new HttpParams()
        .append('userId', '')
        .append('question_id', previous_question.toString());
      return this.http.get<Object>(url, {params});
    }
    
  }
  public submitAnswer(data): Observable<any> {
    this.getUserData();
    if (this.USER) {
      const url = `${this.env.apiBase}/auth/user/question`;
      let params = new HttpParams()
        .append('question_id', data.question_id)
        .append('answer_id', data.answer_id)
        .append('userId', this.USER.user_id)
        .append('value', (data.value) ? data.value : '');
      return this.http.get<Object>(url, {params});
    } else {
      const url = `${this.env.apiBase}/user/question`;
      let params = new HttpParams()
        .append('question_id', data.question_id)
        .append('answer_id', data.answer_id.toString())
        .append('userId', '')
        .append('value', (data.value) ? data.value : '');
      console.log(params);
      return this.http.get<Object>(url, {params});
    }
    
  }
  
}
