import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  
  public user = JSON.parse(localStorage.getItem('currentUser'));
  
  constructor() { }

  ngOnInit() { }

}
